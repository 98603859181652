@import '../node_modules/@ingka/grid/_mixins.scss';
$prefix: 'my-prefix-';

.app-page-container {
  @include grid-container($small: 3, $medium: 9, $large: 12, $columnGap: true, $rowGap: true);

  > * {
    grid-column: 2 / 12;
  }
}

.app-page-container__main {
  margin-top: 1.5rem;
}
