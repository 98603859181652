@use '../node_modules/normalize.css/normalize.css' as normalize;
@use '../node_modules/@ingka/core/style.scss' as core;
@use '../node_modules/@ingka/typography/style.scss' as typography;
@use '../node_modules/@ingka/text/style.scss' as text;
@use '../node_modules/@ingka/loading/style.scss' as loading;
@use '../node_modules/@ingka/list/style.scss' as list;
@use '../node_modules/@ingka/focus/style.scss' as focus;
@use '../node_modules/@ingka/forms/style.scss' as forms;
@use '../node_modules/@ingka/pill/style.scss' as pill;
@use '../node_modules/@ingka/choice/style.scss' as choice;
@use '../node_modules/@ingka/button/style.scss' as button;
@use '../node_modules/@ingka/tooltip/style.scss' as tooltip;
@use '../node_modules/@ingka/search/style.scss' as search;
@use '../node_modules/@ingka/modal/style.scss' as modal;
@use '../node_modules/@ingka/inline-message/style.scss' as inline-message;
@use '../node_modules/@ingka/tabs/style.scss' as tabs;
@use '../node_modules/@ingka/carousel/style.scss' as carousel;
@use '../node_modules/@ingka/svg-icon/style.scss' as svg-icon;
@use '../node_modules/@ingka/aspect-ratio-image/style.scss' as aspect-ratio-image;
@use '../node_modules/@ingka/shoppable-image/style.scss' as shoppable-image;
@use '../node_modules/@ingka/accordion/style.scss' as accordion;
@use '../node_modules/@ingka/image-info/style.scss' as image-info;
@use '../node_modules/@ingka/skeleton/style.scss' as skeleton;
@use '../node_modules/@ingka/aspect-ratio-box/style.scss' as aspect-ratio-box;
@use '../node_modules/@ingka/expander/style.scss' as expander;

/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include body($prefix);
}
    */

/*
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/

body {
  overflow-y: scroll;
}
